<template>
  <v-card>
    <v-card-title>Purchase Orders</v-card-title>
    <v-card-text class="d-flex align-center flex-wrap pb-0">
      <!-- search -->
      <v-text-field
        v-if="!advancedFilter"
        v-model="searchQuery"
        clearable
        placeholder="Search"
        outlined
        hide-details
        dense
        class="user-search me-3 mb-4"
        @keyup.enter="fetchPurchaseOrders"
        @click:clear="clearSearch"
      >
      </v-text-field>

      <v-spacer></v-spacer>
      <div class="d-flex align-center flex-wrap">
        <v-btn
          color="primary"
          class="mb-4 me-3"
          @click="advancedFilter = !advancedFilter"
        >
          <v-icon>{{ icons.mdiFilterOutline }}</v-icon>
          <span v-if="!advancedFilter">&nbsp; Advanced Filter</span>
          <span v-else>&nbsp; Hide Filter</span>
        </v-btn>
        <v-btn
          color="primary"
          class="mb-4 me-3"
          outlined
          @click="exportMatrix"
        >
          <v-icon>{{ icons.mdiMicrosoftExcel }}</v-icon>
          <span>&nbsp; Export Matrix</span>
        </v-btn>
      </div>
    </v-card-text>
    <v-card-text v-show="advancedFilter">
      <v-row>
        <v-col
          cols="12"
          md="2"
        >
          <v-autocomplete
            v-model="formSearch.year"
            clearable
            aria-required=""
            :items="years"
            cache-items
            hide-details="auto"
            label="Year"
            outlined
            dense
          ></v-autocomplete>
        </v-col>
        <v-col
          cols="12"
          md="2"
        >
          <v-autocomplete
            v-model="formSearch.quarter"
            clearable
            aria-required=""
            :items="['1st Quarter', '2nd Quarter', '3rd Quarter', '4th Quarter']"
            cache-items
            hide-details="auto"
            label="Quarter"
            outlined
            dense
          ></v-autocomplete>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <office-select
            v-model="formSearch.office_name"
            @setDepartment="setDepartment"
          ></office-select>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <supplier-autocomplete
            v-model="formSearch.supplier_id"
            @setSupplier="setSupplier"
          ></supplier-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="formSearch.pr_number"
            clearable
            hide-details="auto"
            label="PR No"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="formSearch.quotation_number"
            clearable
            hide-details="auto"
            label="Quotation No"
            outlined
            dense
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-text-field
            v-model="formSearch.po_number"
            clearable
            hide-details="auto"
            label="PO No"
            outlined
            dense
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col
          cols="12"
          md="4"
        >
          <v-autocomplete
            v-model="formSearch.payment_term"
            clearable
            :items="paymentTerms"
            hide-details="auto"
            label="Payment Term"
            outlined
            dense
            hide-no-data
          >
          </v-autocomplete>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-autocomplete
            v-model="formSearch.delivery_term"
            clearable
            :items="deliveryTerms"
            hide-details="auto"
            label="Delivery Term"
            outlined
            dense
            hide-no-data
          >
          </v-autocomplete>
        </v-col>

        <v-col
          cols="12"
          md="4"
        >
          <v-autocomplete
            v-model="formSearch.procurement_mode"
            clearable
            :items="procurementModes"
            hide-details="auto"
            label="Mode of Procurement"
            outlined
            dense
            hide-no-data
          >
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="4"
        >
          <v-autocomplete
            v-model="formSearch.date_type"
            clearable
            aria-required=""
            placeholder="Search.. ."
            :items="['PO Date', 'Delivery Date', 'Date Served', 'Inspection Date', 'For Payment', 'For Cheque']"
            hide-details="auto"
            label="Date Type"
            outlined
            dense
            clearable
          ></v-autocomplete>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-menu
            v-model="dateFrom"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="formSearch.date_from"
                clearable
                label="From Date"
                :prepend-icon="icons.mdiCalendarCheckOutline"
                readonly
                v-bind="attrs"
                outlined
                dense
                clearable
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="formSearch.date_from"
              color="primary"
              @input="dateFrom = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-menu
            v-model="dateTo"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="formSearch.date_to"
                clearable
                label="To Date"
                :prepend-icon="icons.mdiCalendarCheckOutline"
                readonly
                v-bind="attrs"
                outlined
                dense
                clearable
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="formSearch.date_to"
              color="primary"
              @input="dateTo = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col cols="12">
          <div class="d-flex align-center flex-wrap">
            <v-btn
              color="primary"
              class="mb-4 me-3"
              @click="advancedSearch"
            >
              <span>Search</span>
            </v-btn>
            <v-btn
              class="mb-4 me-3"
              outlined
              @click="resetFilter"
            >
              <span>Reset Filter</span>
            </v-btn>
          </div>
        </v-col>
      </v-row>
      </v-row>
    </v-card-text>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="purchaseOrders"
        :options.sync="options"
        :server-items-length="totalPurchaseOrder"
        :footer-props="footerProps"
        @page-count="pageCount = $event"
      >
        <!-- po_number -->
        <template #[`item.po_number`]="{ item }">
          <div class="d-flex flex-column">
            <router-link
              class="font-weight-semibold text-decoration-none"
              :to="{ name: 'purchase-orders-edit', params: { id: item.id } }"
            >
              {{ item.po_number }}
            </router-link>
          </div>
        </template>

        <!-- quotation_number -->
        <template #[`item.quotation_number`]="{ item }">
          <div class="d-flex flex-column">
            <router-link
              class="font-weight-semibold text-decoration-none"
              :to="{ name: 'rfqs-edit', params: { id: item.rfq_id } }"
            >
              {{ item.rfq.quotation_number }}
            </router-link>
          </div>
        </template>

        <!-- owner -->
        <template #[`item.owner`]="{ item }">
          <span>{{ item.owner_first_name }} {{ item.owner_last_name }}</span>
        </template>

        <!-- tel_cel  -->
        <template #[`item.tel_cel`]="{ item }">
          <span>{{ item.tel_number }}<span v-if="item.cell_number">/ {{ item.cell_number }}</span></span>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{ item }">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item @click="editItem(item)">
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiPencilOutline }}
                  </v-icon>
                  <span>Edit</span>
                </v-list-item-title>
              </v-list-item>
              <v-list-item @click="deleteItemConfirm(item)">
                <v-list-item-title>
                  <v-icon
                    size="20"
                    class="me-2"
                  >
                    {{ icons.mdiDeleteOutline }}
                  </v-icon>
                  <span>Delete</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </template>
      </v-data-table>
      <!-- dialog -->
      <v-dialog
        v-model="dialogDelete"
        max-width="500px"
      >
        <v-card>
          <v-card-title> Are you sure you want to delete this item? </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="error"
              outlined
              @click="dialogDelete = !dialogDelete"
            >
              Cancel
            </v-btn>
            <v-btn
              color="success"
              @click="deleteItem"
            >
              OK
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-text>
    <snackbar
      ref="snackbar"
      :message="message"
      color="error"
    ></snackbar>
  </v-card>
</template>

<script>
import {
  mdiSquareEditOutline,
  mdiDotsVertical,
  mdiPlus,
  mdiExportVariant,
  mdiPencilOutline,
  mdiDeleteOutline,
  mdiEyeOutline,
  mdiCarPickup,
  mdiFilterOutline,
  mdiMicrosoftExcel,
} from '@mdi/js'
import { onMounted, ref, watch } from '@vue/composition-api'
import axios from '@axios'
import router from '@/router'
import useUIResolver from '../useUIResolver'
import usePurchaseOrders from './usePurchaseOrders'
import OfficeSelect from '../OfficeSelect.vue'
import SupplierAutocomplete from '../suppliers/SupplierAutocomplete.vue'
import Snackbar from '../Snackbar.vue'

const formSearchInit = () => ({
  quarter: null,
  year: null,
  office_name: null,
  supplier_id: null,
  date_type: null,
  date_from: null,
  date_to: null,
  payment_term: null,
  delivery_term: null,
  procurement_mode: null,
  pr_number: null,
  quotation_number: null,
  po_number: null,
})

export default {
  components: { OfficeSelect, SupplierAutocomplete, Snackbar },
  setup() {
    const snackbar = ref(null)
    const message = ref(null)
    const formSearch = ref(formSearchInit())
    const purchaseOrderData = ref({})
    const searchQuery = ref('')
    const totalPurchaseOrder = ref(0)
    const advancedFilter = ref(false)
    const dateFrom = ref(false)
    const dateTo = ref(false)
    const footerProps = ref({
      itemsPerPageOptions: [15, 50, 100],
    })
    const options = ref({
      groupBy: [],
      groupDesc: [],
      itemsPerPage: 15,
      multiSort: false,
      mustSort: false,
      page: 1,
      sortBy: ['id'],
      sortDesc: [true],
    })
    const dialogDelete = ref(false)
    const headers = ref([])

    const {
      poHeaders, resolveDate, years, deliveryTerms, paymentTerms, procurementModes,
    } = useUIResolver()
    const { purchaseOrders, getPurchaseOrders } = usePurchaseOrders()

    const create = () => {
      router.push({ name: 'purchase-orders-create' })
    }

    const fetchPurchaseOrders = () => {
      getPurchaseOrders({ ...options.value, ...{ search: searchQuery.value }, ...formSearch.value }).then(response => {
        totalPurchaseOrder.value = response.data.total
        options.value.itemsPerPage = response.data.per_page
      })
    }

    onMounted(() => {
      headers.value = poHeaders()

      fetchPurchaseOrders()
    })

    const setDepartment = val => {
      formSearch.value.office_name = val
    }

    const setSupplier = val => {
      formSearch.value.supplier_id = val
    }
    watch(options, fetchPurchaseOrders)

    const clearSearch = () => {
      searchQuery.value = null
      fetchPurchaseOrders()
    }

    const editItem = item => {
      router.push({ name: 'purchase-orders-edit', params: { id: item.id } })
    }

    const deleteItem = () => {
      axios.delete(`/purchaseOrders/${purchaseOrderData.value.id}`).then(() => {
        fetchPurchaseOrders()
        dialogDelete.value = false
      })
    }

    const deleteItemConfirm = item => {
      purchaseOrderData.value = item
      dialogDelete.value = true
    }

    const advancedSearch = () => {
      fetchPurchaseOrders()
    }

    const resetFilter = () => {
      formSearch.value = formSearchInit()
      fetchPurchaseOrders()
    }

    const exportMatrix = () => {
      if (formSearch.value.year === null || formSearch.value.quarter === null) {
        snackbar.value.visible = true
        message.value = 'Please select year/quarter to generate procurement matrix.'

        return false
      }

      window.location.href = `${process.env.VUE_APP_SERVER_URL}/export-po-matrix?year=${formSearch.value.year}&quarter=${formSearch.value.quarter}`
    }

    return {
      formSearch,
      advancedSearch,
      resetFilter,
      dialogDelete,
      purchaseOrderData,
      headers,
      searchQuery,
      totalPurchaseOrder,
      footerProps,
      advancedFilter,
      dateFrom,
      dateTo,
      options,
      resolveDate,
      deliveryTerms,
      paymentTerms,
      procurementModes,
      years,
      create,
      snackbar,
      message,

      // functions
      clearSearch,
      setDepartment,
      setSupplier,
      exportMatrix,

      purchaseOrders,
      fetchPurchaseOrders,
      getPurchaseOrders,

      editItem,
      deleteItem,
      deleteItemConfirm,

      // icons
      icons: {
        mdiSquareEditOutline,
        mdiDotsVertical,
        mdiPlus,
        mdiExportVariant,
        mdiPencilOutline,
        mdiDeleteOutline,
        mdiEyeOutline,
        mdiCarPickup,
        mdiFilterOutline,
        mdiMicrosoftExcel,
      },
    }
  },
}
</script>
