<template>
  <v-autocomplete
    :value="value"
    placeholder="Search.. ."
    :loading="loadingSupplier"
    :items="supplierOptions"
    :search-input.sync="searchSupplier"
    hide-details="auto"
    label="Supplier"
    item-text="supplier_name"
    item-value="id"
    outlined
    dense
    hide-no-data
    return-object
    :error-messages="errorMessage"
    clearable
    @keyup.enter="fetchSuppliers"
    @change="updateValue"
  >
    <template
      v-if="add"
      v-slot:append-outer
    >
      <v-slide-x-reverse-transition
        mode="out-in"
      >
        <v-btn
          color="primary"
          x-small
          fab
        >
          <v-icon
            @click="addNew"
          >
            {{ icons.mdiPlus }}
          </v-icon>
        </v-btn>
      </v-slide-x-reverse-transition>
    </template>
  </v-autocomplete>
</template>

<script>
import { onMounted } from '@vue/composition-api'
import { mdiPlus } from '@mdi/js'
import useSuppliers from './useSuppliers'

export default {
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    add: {
      type: Boolean,
      default: false,
    },
    errorMessage: null,
    value: null,
    initial: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const {
      suppliers: supplierOptions, searchSupplier, getSuppliers, loading: loadingSupplier,
    } = useSuppliers()

    const fetchSuppliers = () => {
      getSuppliers({ search: searchSupplier.value })
    }

    const updateValue = val => {
      emit('setSupplier', val)
    }

    onMounted(() => {
      if (props.initial.length > 0 && props.value !== null) {
        supplierOptions.value = props.initial.filter(o => o.id > 0 && o.id !== null)
      }
    })

    const addNew = () => {
      //
    }

    return {
      supplierOptions,
      getSuppliers,
      loadingSupplier,
      fetchSuppliers,
      searchSupplier,

      updateValue,

      addNew,

      icons: {
        mdiPlus,
      },
    }
  },
}
</script>
