<template>
  <div>
    <v-snackbar
      v-model="visible"
      :light="$vuetify.theme.light"
      top
      :color="color"
    >
      {{ message }}

      <template #action="{ attrs }">
        <v-btn
          color="#ffffff"
          text
          v-bind="attrs"
          @click="visible = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'

export default {
  props: {
    message: null,
    color: {
      type: String,
      default: '#515151',
      required: false,
    },
  },
  setup() {
    const visible = ref(false)

    return { visible }
  },
}
</script>
