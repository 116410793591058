<template>
  <div>
    <v-autocomplete
      :value="value"
      placeholder="Search.. ."
      :loading="loadingDepartment"
      :items="departmentOptions"
      :search-input.sync="searchDepartment"
      hide-details="auto"
      label="Office"
      item-text="name"
      item-value="name"
      outlined
      dense
      hide-no-data
      clearable
      :error-messages="error"
      @keyup.enter="fetchDepartments"
      @change="updateValue"
    >
      <template v-slot:append-outer>
        <v-slide-x-reverse-transition mode="out-in">
          <v-btn
            color="primary"
            x-small
            fab
            @click="addNew"
          >
            <v-icon>
              {{ icons.mdiPlus }}
            </v-icon>
          </v-btn>
        </v-slide-x-reverse-transition>
      </template>
    </v-autocomplete>
    <office-editor-dialog
      ref="officeEditorDialog"
      @saved="saved"
    ></office-editor-dialog>
  </div>
</template>

<script>
import { mdiPlus } from '@mdi/js'
import { onMounted, ref } from '@vue/composition-api'
import OfficeEditorDialog from './rfq/OfficeEditorDialog.vue'
import useDepartments from './useDepartments'

export default {
  components: { OfficeEditorDialog },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    error: null,
    value: null,
    initial: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const officeEditorDialog = ref(null)
    const {
      departments: departmentOptions,
      searchDepartment,
      getDepartments,
      loading: loadingDepartment,
    } = useDepartments()

    const fetchDepartments = () => {
      getDepartments({ search: searchDepartment.value })
    }

    const updateValue = val => {
      emit('setOffice', val)
    }

    const addNew = () => {
      officeEditorDialog.value.isDialogVisible = true
    }

    const saved = data => {
      departmentOptions.value.push(data)
      emit('setOffice', data.name)
    }

    onMounted(() => {
      if (props.initial.length > 0 && props.value !== null) {
        departmentOptions.value = props.initial
      }

      console.log('DSDSD', props.initial)

      fetchDepartments()
    })

    return {
      departmentOptions,
      getDepartments,
      loadingDepartment,
      fetchDepartments,
      searchDepartment,

      officeEditorDialog,

      updateValue,
      addNew,

      saved,

      icons: {
        mdiPlus,
      },
    }
  },
}
</script>
