import axios from '@axios'
import { ref } from '@vue/composition-api'

export default function usePurchaseOrder() {
  const purchaseOrders = ref([])
  const purchaseOrder = ref({})
  const loading = ref(false)
  const searchPurchaseOrder = ref(null)

  const getPurchaseOrders = params => {
    loading.value = true

    return new Promise((resolve, reject) => {
      axios
        .post('/purchase-orders/filter', params)
        .then(response => {
          const { data } = response.data
          purchaseOrders.value = data
          loading.value = false
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  const getPurchaseOrder = id => {
    loading.value = true

    return new Promise((resolve, reject) => {
      axios
        .get(`/purchase-orders/${id}`)
        .then(response => {
          const { data } = response
          purchaseOrder.value = data
          loading.value = false
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  }

  return {
    // variables
    loading,
    searchPurchaseOrder,
    purchaseOrders,
    purchaseOrder,

    // functions
    getPurchaseOrders,
    getPurchaseOrder,
  }
}
